<template>
	<div class="account-settings">
		<account-subscription-settings
			class="account-subscription-settings"
			v-if="isAdmin($store.getters.getUserRole) && $store.getters.isClient"
		>
		</account-subscription-settings>
		<account-data-settings
			class="account-data-settings"
			:small="!$store.getters.isClient"
			v-if="isAdmin($store.getters.getUserRole)"
		></account-data-settings>
		<user-password-settings class="password-settings"></user-password-settings>
	</div>
</template>

<script>
import rolesMixin from '@/mixins/roles.mixin';
export default {
	components: {
		userPasswordSettings: () =>
			import('@/components/settings/panels/user-password-settings.vue'),
		accountSubscriptionSettings: () =>
			import('@/components/settings/panels/account-subscription-settings.vue'),
		accountDataSettings: () =>
			import('@/components/settings/panels/account-data-settings.vue')
	},
	mixins: [rolesMixin]
};
</script>

<style lang="scss">
.account-settings {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr auto;
	box-shadow: 0 0 20px -12px #394150;
	padding: 3rem;
	background-color: $background-color;
	justify-content: center;
	align-items: flex-start;
	justify-items: center;
	.account-subscription-settings {
		grid-column: 1/3;
		grid-row: 1;
	}
	.password-settings {
		grid-column: 2;
		grid-row: 2;
		background-color: #f3f3f3;
		height: 100%;
	}
	.account-data-settings {
		grid-column: 1;
		grid-row: 2;
		background-color: #f3f3f3;
		height: 100%;
	}
}
</style>
